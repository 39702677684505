export default {
  SET_LANDING_PAGE_SECTION: "set-landing-page-section",
  ADD_OPTIONS_SCROLL_TARGET: "add-options-scroll-target",
  SET_OPTIONS_SCROLL_TARGET: "set-options-scroll-target",
  UPDATE_OPTIONS_SCROLL_TARGET: "update-options-scroll-target",
  REMOVE_OPTIONS_SCROLL_TARGET: "remove-options-scroll-target",
  RESET_OPTIONS_SCROLL_TARGET: "reset-options-scroll-target",

  SET_POP_UP: "set-pop-up",
  SET_IS_OPEN_POP_UP: "set-is-open-pop-up",
  SET_CLOSE_POP_UP: "set-close-pop-up",
};
