export default {
  GET_LANDING_PAGE_PRODUCTS: "get-landing-page-products",
  GET_LANDING_PAGE_PRODUCTS_SUCCESS: "get-landing-page-products-success",
  SET_COUNT_GET_LANDING_PAGE_PRODUCTS: "set-count-get-landing-page-products",
  IS_LOADING_GET_LANDING_PAGE_PRODUCTS: "is-loading-get-landing-page-products",

  GET_LANDING_PAGE_PRODUCT: "get-detail-page-product",
  GET_LANDING_PAGE_PRODUCT_SUCCESS: "get-detail-page-product-success",
  IS_LOADING_GET_LANDING_PAGE_PRODUCT: "is-loading-get-detail-page-product",
  RESET_LANDING_PAGE_PRODUCT: "reset-detail-landing-page-product",

  ADD_LANDING_PAGE_PRODUCT: "add-landing-page-product",
  ADD_LANDING_PAGE_PRODUCT_SUCCESS: "add-landing-page-product-success",
  IS_LOADING_ADD_LANDING_PAGE_PRODUCT: "is-loading-add-landing-page-product",
  RESET_ADD_LANDING_PAGE_PRODUCT: "reset-add-landing-page-product",

  UPDATE_LANDING_PAGE_PRODUCT: "update-landing-page-product",
  UPDATE_LANDING_PAGE_PRODUCT_SUCCESS: "update-landing-page-product-success",
  IS_LOADING_UPDATE_LANDING_PAGE_PRODUCT:
    "is-loading-update-landing-page-product",
  RESET_UPDATE_LANDING_PAGE_PRODUCT: "reset-update-landing-page-product",

  DELETE_LANDING_PAGE_PRODUCT: "delete-landing-page-product",
  DELETE_LANDING_PAGE_PRODUCT_SUCCESS: "delete-landing-page-product-success",
  IS_LOADING_DELETE_LANDING_PAGE_PRODUCT:
    "is-loading-delete-landing-page-product",

  GET_PHOTO_GALLERY: "get-photo-gallery",
  GET_PHOTO_GALLERY_SUCCESS: "get-photo-gallery-success",
  IS_LOADING_GET_PHOTO_GALLERY: "is-loading-get-photo-gallery",
  SET_COUNT_PHOTO_GALLERY: "set-count-photo-gallery",

  UPLOAD_PHOTO_GALLERY: "upload-photo-gallery",
  UPLOAD_PHOTO_GALLERY_SUCCESS: "upload-photo-gallery-success",
  IS_LOADING_UPLOAD_PHOTO_GALLERY: "is-loading-upload-photo-gallery",

  DELETE_PHOTO_GALLERY: "delete-photo-gallery",
  DELETE_PHOTO_GALLERY_SUCCESS: "delete-photo-gallery-success",
  IS_LOADING_DELETE_PHOTO_GALLERY: "is-loading-delete-photo-gallery",
};
